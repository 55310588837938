<template>
    <div class="home">
        <nav class="navTab">
            <div class="isDivcom"><h2>普通用户密码管理</h2></div>
           
        </nav>
        <nav class="nav_sech" v-if="tabIndex==0">
            <div style="width:224px;padding-left:15px">
                 <!--suffix-icon="el-icon-search" -->
                <el-input
                    size="medium "
                    placeholder="请输入账号/关键字"
                    v-model="keywords">
                    <i slot="suffix" @click="searchName" class="el-input__icon el-icon-search"></i>
                </el-input>
            </div>
        </nav>
        <main class="el_table" style="margin: 15px 15px 0" v-if="tabIndex==0">
            <el-table
                :data="tableData"
                style="width: 100%;margin-bottom: 20px"
                align="center"
                height="55vh"
                border>
                <el-table-column
                    prop="account"
                    label="账号"
                    align="center"
                    width="250">
                </el-table-column>
                <el-table-column
                    prop="username"
                    label="用户名"
                    align="center"
                    width="250">
                </el-table-column>
                <el-table-column
                    prop="email"
                    label="邮件"
                    align="center"
                    width="300">
                </el-table-column>
                <el-table-column
                    prop="createtime"
                    label="创建时间"
                    align="center"
                    width="300">
                </el-table-column>
                <el-table-column
                    prop="updatetime"
                    label="更新时间"
                    align="center"
                    width="300">
                </el-table-column>
                <el-table-column label="操作" align="center" width="215">
                    <template slot-scope="scope">
                       <el-button
                            size="medium "
                            type="danger"
                            @click="handleEditPwd(scope.row.id)">重置密码
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div  style="height:60px;text-align: center;">
                <el-pagination
                    background
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :page-sizes="[10,20, 30, 40, 50]"
                    :page-size="10"
                    :current-page="pages"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="currenttotal">
                </el-pagination>
            </div>
        </main>

        <!--密码弹出框-->
        <el-dialog
            title="修改密码"
            :visible.sync="dialogPassword"
            width="38%">
            <template>
                <el-form ref="permisPassword" size="medium " :model="permisPassword" label-width="70px">
                    <el-form-item label="新密码">
                        <el-input show-password v-model="permisPassword.newpwd" placeholder="请输入新密码"></el-input>
                    </el-form-item>
                    <el-form-item  label="确认密码">
                        <el-input show-password v-model="permisPassword.newpwds" placeholder="请确认密码"></el-input>
                    </el-form-item>
                </el-form>
            </template>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogPassword = false">取 消</el-button>
                <el-button type="primary" @click="PasswordData">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: "Person",
        data(){
            return{
                tabIndex:0,
                queryform:{},

                dialogPassword:false,//重置密码
                permisPassword:{},

                tableData:[],
                roleData: [],
                keywords:'',
               
                pages:1,
                limits:10,
                currenttotal:null
            }
        },
        created(){
            this.postableData(this.keywords)
        },
        methods:{
           //收索
           searchName:function(){
               this.pages=1;
               this.postableData(this.keywords)
           },
           //获取列表数据
           postableData:function(key){
             var _self=this;
                axios.get(this.API.person.list,{
                    headers:{
                        token:this.Util.getjwtToken(),
                    },
                    params:{
                        keywords:key,
                        page:this.pages,
                        limit:this.limits
                    }
                }).then(function (res) {
                    if(res.data.success){
                        _self.tableData=res.data.data.list;
                        _self.currenttotal=res.data.data.pager.recordCount;
                    }
                })
          },



           //密码重置
           handleEditPwd:function(eid){
               this.dialogPassword=true;
               this.eid=eid
           },
           PasswordData:function(){
               if(this.Util.isBlank(this.permisPassword.newpwd)){
                 this.$message.warning('请输入新密码！');
                 return   
               }
               if(this.Util.isBlank(this.permisPassword.newpwds)){
                 this.$message.warning('请确认新密码！');
                 return   
               }
               if(this.permisPassword.newpwd!=this.permisPassword.newpwds){
                 this.$message.warning('确认密码不一致！');
                 return  
               }
               this.getEditPwdrow()
           },
           //提交重置密码
           getEditPwdrow:function(){
                var _this=this;
                axios.get(this.API.person.setPwd,{
                    headers:{
                        token:this.Util.getjwtToken(),
                    },
                    params:{
                        newPwd:_this.$md5(_this.permisPassword.newpwd),
                        id:this.eid
                    }
                }).then(function (res) {
                    if(res.data.success){
                        _this.$message.success(res.data.msg);
                        _this.dialogPassword=false;
                        _this.permisPassword={};
                    }else{
                        _this.$message.error(res.data.msg);
                    }
                })
           },

          //分页
           handleSizeChange:function(e){
             this.limits=e;
             this.postableData(this.keywords);//重新获取列表
           },
           handleCurrentChange:function(e){
             this.pages=e;
             this.postableData(this.keywords);//重新获取列表
           },
        }
    }
</script>

<style scoped lang="scss">
.el-input__icon{
   cursor: pointer;
}
.isDivcom{
     color:#000;
     border-bottom: 2px solid #000000;
 }
</style>